/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import '../node_modules/leaflet/dist/leaflet.css';

.p-picklist-target-controls , .p-picklist-source-controls {
display: none !important;
}

#pn_id_2-table > .p-datatable-thead > tr > th:nth-child(1), #pn_id_2-table > .p-datatable-tbody > tr > td:nth-child(1), #pn_id_2-table > .p-datatable-tfoot > tr > td:nth-child(1) {
    width: 40px !important;
    max-width: 40px !important;
}

.p-sortable-column {
    min-width: 12rem
}

.p-column-filter-buttonbar {
    gap: 2rem !important
}